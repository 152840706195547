// Bootstrap
$bs-grid-columns        : 60;
$bs-grid-gutter-width   : 0px;

// Sizes
$max-width              : 414px;

// Custom breakpoints
$grid-breakpoints       : (
	xs  : 0,
	sm  : 576px,
	md  : 768px,
	lg  : 992px,
	xl  : 1200px,
	xxl : 1400px
) !default;

// Font weights
$font-weight-light      : 300;
$font-weight-regular    : 400;
$font-weight-medium     : 500;
$font-weight-demi-bold  : 600;
$font-weight-bold       : 700;
$font-weight-extra-bold : 800;
$font-weight-black      : 900;

// Columns definitions
$default-col-width      : 54;
$default-col-offset     : 3;
$md-col-width           : 56;
$md-col-offset          : 2;