@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

.loader {
	$self : &;
	
	&:not(#{$self}--inline) {
		z-index: 1000;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		
		#{$self}__opaque {
			position: absolute;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background-color: rgba(0, 0, 0, .75);
		}
		
		#{$self}__content {
			@include vertical-align;
			width: 100%;
		}
		
		#{$self}__spinner {
			margin: 0 auto 1.5rem;
			font-size: 1rem;
			position: relative;
			text-indent: -9999em;
			border-top: 0.5rem solid $darkest-green;
			border-right: 0.5rem solid $darkest-green;
			border-bottom: 0.5rem solid $darkest-green;
			border-left: 0.5rem solid $brightest-green;
			transform: translateZ(0);
			animation: load 1.1s infinite linear;
			
			&, &:after {
				border-radius: 50%;
				width: 4.5rem;
				height: 4.5rem;
			}
			
			@keyframes load {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
			
		}
		
		#{$self}__text {
			font-size: 1.2rem;
			color: #fff;
			text-align: center;
		}
		
	}
	
	&#{$self}--inline {
		padding: 0.5rem 0;
		
		#{$self}__content {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
		}
		
		#{$self}__spinner {
			font-size: 1rem;
			position: relative;
			text-indent: -9999em;
			border-top: 0.25rem solid $lighter-grey ;
			border-right: 0.25rem solid $lighter-grey ;
			border-bottom: 0.25rem solid $lighter-grey ;
			border-left: 0.25rem solid $green;
			transform: translateZ(0);
			animation: load 1.1s infinite linear;
			
			&, &:after {
				border-radius: 50%;
				width: 1.8rem;
				height: 1.8rem;
			}
			
			@keyframes load {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}
			
		}
		
		#{$self}__text {
			margin-left: 1rem;
			font-size: 1.4rem;
		}
	
	}
	
}