@charset "UTF-8";

// Globals
@import "src/Assets/Styles/utilities/includes";

.button {
	display: inline-block;
	padding: 0.8rem 1.6rem 0.8rem;
	background-color: $lightest-green;
	border-radius: 1.4rem;
	transition: background-color 0.25s ease, color 0.25s ease;
	font: {
		size: 1.4rem;
		weight: $font-weight-regular;
	}
	line-height: 1;
	text-decoration: none;
	color: $green;
	
	&--selected {
		background-color: $green;
		color: #fff;
	}
	
}