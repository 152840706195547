@charset "UTF-8";

/**
 * Remove iOS custom form styles
 */

textarea, select,
input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]) {
	-webkit-appearance: none;
	border-radius: 0;
}

/**
 * iOS scrolling elements / disable font size adjustments
 * @see https://stackoverflow.com/a/50452058/9503418
 * @see https://stackoverflow.com/questions/2710764/preserve-html-font-size-when-iphone-orientation-changes-from-portrait-to-landsca
 */

body {
	-webkit-overflow-scrolling: touch;
	-webkit-text-size-adjust: 100%;
}