@charset "UTF-8";

// Globals
@import "src/Assets/Styles/config/colors";
@import "src/Assets/Styles/config/variables";
@import "src/Assets/Styles/utilities/mixins";
@import "src/Assets/Styles/utilities/reset";
@import "src/Assets/Styles/utilities/utilities";

/**
 * Bootstrap
 */

// Bootstrap overrides (custom columns and gutters)
$grid-columns      : $bs-grid-columns !default;
$grid-gutter-width : $bs-grid-gutter-width !default;

// Bootstrap
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/bootstrap-grid";
@import "node_modules/bootstrap/scss/images";

/**
 * Common
 */

*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	// @see https://stackoverflow.com/a/60516252/9503418
	touch-action: pan-x pan-y;
	height: 100%;
	font-size: 62.5%;
}

html, body, #root {
	overscroll-behavior: none;
}

body {
	background-color: $darkest-green;
	@include inter {
		font-size: 1.6rem;
		color: $text-color;
	};
}

#root {
	max-width: $max-width;
	// @see https://github.com/tailwindlabs/tailwindcss/discussions/4515
	min-height: calc(100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0));
	margin: 0 auto;
	background-color: $lightest-grey;
	
	@supports (-webkit-touch-callout: none) {
		min-height: -webkit-fill-available;
	}
	
}

img {
	display: block;
	@extend .img-fluid;
}

a {
	color: $brightest-green;
	text-decoration: underline;
}

.container-fluid {
	position: relative;
	background-color: $lightest-grey;
}

// Extends
@import "src/Assets/Styles/utilities/extends";

// Components
@import "src/Assets/Styles/components/webfonts";
@import "src/Assets/Styles/components/loader";
@import "src/Assets/Styles/components/buttons";