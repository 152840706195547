@charset "UTF-8";

/**
 * Fontface mixin
 * @param string $weight Font family
 * @param int    $weight Font weight
 * @param type   $style  Font Style
 * @return rule
 */

@mixin font-face($family, $weight: 400, $style: normal, $fallback: sans-serif) {
	font-family: '#{$family}', $fallback;
	font-weight: $weight;
	font-style: $style;
	@content;
}

/**
 * Inter mixin
 * @param int  $weight Font weight
 * @param type $style  Font Style
 * @return rule
 */

@mixin inter($weight: $font-weight-light, $style: normal) {
	@include font-face('Inter', $weight, $style) {
		@content;
	};
}

/**
 * Icons mixin
 * @return rule
 */

@mixin icons() {
	@include font-face('CustomIcons', normal) {
		@content;
	};
}

/**
 * Input placeholder styles
 */

@mixin placeholder() {
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder,
	&:-moz-placeholder,
	&::placeholder {
		@content;
	}
}

/**
 * Last child margin reset
 * @param property $property
 */

@mixin last-child($property: margin-bottom, $value: 0) {
	&:last-child {
		#{$property}: $value;
		@content;
	}
}

/**
 * Computes a percentage
 */

@function compute($size: $bs-grid-columns, $columns: $bs-grid-columns) {
	@return percentage(calc($size / $columns));
}

/**
 * Vertical align using position absolute
 */

@mixin vertical-align($position: absolute, $offset: 50%) {
	position: $position;
	top: $offset;
	transform: translateY(-50%);
}